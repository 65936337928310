export default [
  {
    title: "Dashboards",
    icon: "HomeIcon",
    tag: "1",
    tagVariant: "light-warning",
    children: [
      {
        title: "eCommerce",
        route: "dashboard-ecommerce",
      },
      // {
      //   title: 'Analytics',
      //   route: 'dashboard-analytics',
      // },
    ],
  },
];
